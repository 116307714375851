export default {
  enableElfsight: false,
  telephone: '+30 210 6107 283',
  instarideTelephone: '+30 211 1986 219',
  googlePlayLink: 'https://play.google.com/store/apps/details?id=gr.instacar.instateam',
  appStoreLink: 'https://apps.apple.com/gr/app/instadriver/id1596457847?l=el',
  listingCards: {
    info: {
      position: 22,
      mobilePosition: 11,
      enabled: true,
      type: 'info',
    },
    instadriver: {
      position: 16,
      mobilePosition: 8,
      enabled: true,
      type: 'content',
    },
    buyInfo: {
      position: 11,
      mobilePosition: 6,
      enabled: true,
      type: 'buyInfo',
    },
  },
  instarideCards: {
    calculationsSlider: {
      position: 9,
      tabletPosition: 7,
      enabled: true,
      type: 'calc',
    },
  },
  enableVendorDetails: false,
  homepageListModels: true,
  enableColorFilters: {
    carListing: false,
    ebikeListing: false,
  },
  enableSideFilterPriceChips: {
    carListing: false,
    ebikeListing: false,
  },
  enableOffersFilter: {
    carListing: true,
    ebikeListing: false,
  },
  leasing: {
    metaxeirismena: {
      imgUrlRewrite: true,
      filters: {
        minimumPrice: 0,
        maximumPrice: 3500,
      },
    },
    ebikes: {
      imgUrlRewrite: true,
      filters: {
        minimumPrice: 0,
        maximumPrice: 250,
      },
    },
    blueground: {
      imgUrlRewrite: true,
      filters: {
        minimumPrice: 0,
        maximumPrice: 3500,
      },
    },
    heron: {
      imgUrlRewrite: true,
      filters: {
        minimumPrice: 0,
        maximumPrice: 3500,
      },
    },
    cu: {
      imgUrlRewrite: true,
      filters: {
        minimumPrice: 0,
        maximumPrice: 3500,
      },
    },
    sell: {
      imgUrlRewrite: true,
      filters: {
        minimumPrice: 0,
        maximumPrice: 3500,
      },
    },
  },
  enableCarModelDescription:
    process.env.ENABLE_CAR_MODEL_DESCRIPTION === 'true',
  enableCampaign: process.env.ENABLE_MARKETING_CAMPAIGN === 'true',
  enableDriveHome: process.env.ENABLE_DRIVE_HOME === 'true',
  typeQueryMap: {
    car: 0,
    bike: 1,
    van: 2,
    scooter: 3,
    patini: 4,
  },
}
