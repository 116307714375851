export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, viewport-fit=cover"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"icon","href":"/android-icon-192x192.png","sizes":"192x192"},{"rel":"shortcut icon","href":"/favicon.ico","sizes":"76x76","type":"image/x-icon"},{"rel":"icon","href":"/favicon.svg","sizes":"any","type":"image/svg+xml"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"},{"rel":"apple-touch-icon","sizes":"76x76","href":"/apple-touch-icon.png"},{"rel":"apple-touch-icon","sizes":"120x120","href":"/apple-touch-icon-120x120.png"},{"rel":"apple-touch-icon","sizes":"152x152","href":"/apple-touch-icon-152x152.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon-180x180.png"}],"style":[],"script":[{"hid":"gtm_script","innerHTML":"\n        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.defer=true;j.src=\n        'https://load.alice.instacar.gr/ycszonow.js?st='+i+dl;f.parentNode.insertBefore(j,f);\n        })(window,document,'script','dataLayer','TGCLQ6J');\n        "}],"noscript":[{"hid":"gtm_noscript","pbody":true,"async":true,"defer":true,"innerHTML":"\n        <iframe src=\"https://load.alice.instacar.gr/ns.html?id=GTM-TGCLQ6J\"\n        height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>\n        "}],"titleTemplate":"%s | instacar","__dangerouslyDisableSanitizersByTagID":{"gtm_noscript":["innerHTML"],"gtm_script":["innerHTML"],"intercom_script":["innerHTML"]},"htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000